import { WeatherIndicator } from "../components/WeatherIndicator";
import "../assets/scss/pages/PageBattery.scss";
import Battery from "../components/Battery";
import { Flex, Show, Skeleton, Text } from "@chakra-ui/react";
import { ReactComponent as Bolt } from "../assets/img/Bolt.svg";
import {
  COLORS,
  getBatteryCapacity,
  getCapacityCharge,
  getCapacityDeCharge,
  getNumberFormatter,
  intervalId,
} from "../utils/toolbox";
import { useLazyGetHomeScreenDataQuery } from "../services/ems.service";
import React, { useEffect, useState } from "react";
import { getSelectedTopo } from "../reducers/topologyReducer";
import { store } from "../store/config";
import { useNavigate } from "react-router-dom";
import { BatteryIndicators } from "../components/BatteryIndicators";
import { BatteryGraphs } from "../components/BatteryGraphs";
import { ResponsiveLayout } from "../layouts/ResponsiveLayout";
import EmsAlert from "../components/EmsAlert";
export const PageBattery: React.FC = () => {
  const [
    getBatteryData,
    { data, isError: batteryDataIsError, isFetching: batteryDataIsFetching },
  ] = useLazyGetHomeScreenDataQuery();
  const batteryData = data?.homeDiagram;

  const selectedTopo = getSelectedTopo(store.getState());
  const [, setTimeRemaining] = useState(intervalId);
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedTopo.id == "") {
      navigate("/redirect");
    }
  }, [selectedTopo]);
  useEffect(() => {
    getBatteryData({ topologyId: selectedTopo.id, date:new Date()});
    let today = new Date();
    const timer1 = setInterval(() => {
      getBatteryData({ topologyId: selectedTopo.id,date:new Date() });
      setTimeRemaining(intervalId);
      today = new Date();
    }, intervalId * 60 * 1000);
    const start = today.getSeconds();
    const timer2 = setInterval(() => {
      if (new Date().getSeconds() >= start) {
        setTimeRemaining(60 - (new Date().getSeconds() - start));
      } else {
        setTimeRemaining(60 - (60 - start + new Date().getSeconds()));
      }
    }, 1000);
    return () => {
      window.clearInterval(timer1);
      window.clearInterval(timer2);
    };
  }, [selectedTopo]);
  return (
    <>
      <Show above="md">
        <ResponsiveLayout
          header={<WeatherIndicator />}
          leftSide={
            batteryDataIsError ? (
              <EmsAlert
                status="error"
                title={"Erreur lors de la récupération des données"}
                description={"Error"}
              ></EmsAlert>
            ) : (
              <Skeleton
                isLoaded={!batteryDataIsFetching}
                className="flex flex-col gap-2 items-center justify-start mx-auto w-full list"
              >
                <div>
                  <BatteryIndicators
                    charge={
                      batteryData && selectedTopo.batteryCapacity
                        ? (
                            getCapacityCharge(
                              getBatteryCapacity(
                                selectedTopo.batteryCapacity as string
                              ),
                              batteryData?.batteryCharge100 as number
                            ) as number
                          ).toFixed(1) + " kWh"
                        : ""
                    }
                    decharge={
                      batteryData && selectedTopo.batteryCapacity
                        ? (
                            getCapacityDeCharge(
                              getBatteryCapacity(
                                selectedTopo.batteryCapacity as string
                              ),
                              batteryData?.batteryCharge100 as number
                            ) as number
                          ).toFixed(1) + " kWh"
                        : ""
                    }
                    nextCharge="12h"
                    isLoaded={!batteryDataIsFetching}
                  />
                </div>
                <div className="flex flex-col">
                  <Text className="max-w-fit m-auto p-1 text-center font-extrabold leading-tight mt-2">
                    {batteryData?.batteryFlowkW !== undefined &&
                      batteryData?.batteryFlowkW > 0 && <span>Charge</span>}
                    {batteryData?.batteryFlowkW !== undefined &&
                      batteryData?.batteryFlowkW < 0 && <span>Décharge</span>}
                  </Text>
                  <Text
                    color="#FFFFFF"
                    background={
                      batteryData?.batteryFlowkW !== undefined
                        ? batteryData?.batteryFlowkW > 0
                          ? COLORS.BATTERYUP
                          : batteryData?.batteryFlowkW < 0
                          ? COLORS.BATTERYDOWN
                          : COLORS.BATTERYNONE
                        : COLORS.EMS_GREEN
                    }
                    className="rounded-full   max-w-fit  m-auto text-center font-extrabold leading-tight mt-2 py-3 px-4"
                  >
                    <Bolt className="inline mb-1 mr-2 " />
                    {Math.abs(batteryData?.batteryFlowkW).toFixed(1)} kW
                  </Text>
                </div>
                <div className="flex flex-row justify-center ml-4">
                  <Battery
                    batteryValue={batteryData?.batteryCharge100}
                    className="h-[200px] md:h-[300px] mx-h-fit"
                  />
                </div>
                <div className="">
                  <Text
                    color="#FFFFFF"
                    background={COLORS.EMS_BLUE}
                    className="rounded-full max-w-fit m-auto text-center font-extrabold leading-tight mt-2 py-3 px-4"
                  >
                    <Bolt className="inline mb-1 mr-2" />
                    {getNumberFormatter().format(
                      batteryData?.batteryCharge100
                    )}{" "}
                    %
                  </Text>
                </div>
              </Skeleton>
            )
          }
          rightSide={
            <Flex
              flex="2"
              flexDirection={"column"}
              className="p-4 overflow-y-auto list"
              gap={2}
              width={"100%"}
            >
              <BatteryGraphs />
            </Flex>
          }
        />
      </Show>
      <Show below="md">
        <ResponsiveLayout>
          {" "}
          <Flex
            flex="2"
            py={2}
            px={2}
            flexDirection={"column"}
            className="overflow-y-auto"
            gap={2}
          >
            {batteryDataIsError ? (
              <EmsAlert
                status="error"
                title={"Erreur lors de la récupération des données"}
                description={"Error"}
              ></EmsAlert>
            ) : (
              <Skeleton
                isLoaded={!batteryDataIsFetching}
                className="flex flex-col gap-2 items-center justify-start mx-auto w-full"
              >
                <div>
                  <BatteryIndicators
                    charge={
                      batteryData && selectedTopo.batteryCapacity
                        ? (
                            getCapacityCharge(
                              getBatteryCapacity(
                                selectedTopo.batteryCapacity as string
                              ),
                              batteryData?.batteryCharge100 as number
                            ) as number
                          ).toFixed(1) + " kWh"
                        : ""
                    }
                    decharge={
                      batteryData && selectedTopo.batteryCapacity
                        ? (
                            getCapacityDeCharge(
                              getBatteryCapacity(
                                selectedTopo.batteryCapacity as string
                              ),
                              batteryData?.batteryCharge100 as number
                            ) as number
                          ).toFixed(1) + " kWh"
                        : ""
                    }
                    nextCharge="12h"
                    isLoaded={!batteryDataIsFetching}
                  />
                </div>
                <div className="flex flex-col">
                  <Text className="max-w-fit m-auto p-1 text-center font-extrabold leading-tight mt-2">
                    {batteryData?.batteryFlowkW !== undefined &&
                      batteryData?.batteryFlowkW > 0 && <span>Charge</span>}
                    {batteryData?.batteryFlowkW !== undefined &&
                      batteryData?.batteryFlowkW < 0 && <span>Décharge</span>}
                  </Text>
                  <Text
                    color="#FFFFFF"
                    background={
                      batteryData?.batteryFlowkW !== undefined
                        ? batteryData?.batteryFlowkW > 0
                          ? COLORS.BATTERYUP
                          : batteryData?.batteryFlowkW < 0
                          ? COLORS.BATTERYDOWN
                          : COLORS.BATTERYNONE
                        : COLORS.EMS_GREEN
                    }
                    className="rounded-full   max-w-fit  m-auto text-center font-extrabold leading-tight mt-2 py-3 px-4"
                  >
                    <Bolt className="inline mb-1 mr-2 " />
                    {Math.abs(batteryData?.batteryFlowkW).toFixed(1)} kW
                  </Text>
                </div>
                <div className="flex flex-row justify-center ml-4">
                  <Battery
                    batteryValue={batteryData?.batteryCharge100}
                    className="h-[200px] md:h-[300px] mx-h-fit"
                  />
                </div>
                <div className="">
                  <Text
                    color="#FFFFFF"
                    background={COLORS.EMS_BLUE}
                    className="rounded-full max-w-fit m-auto text-center font-extrabold leading-tight mt-2 py-3 px-4"
                  >
                    <Bolt className="inline mb-1 mr-2" />
                    {batteryData?.batteryCharge100} %
                  </Text>
                </div>
              </Skeleton>
            )}
            <BatteryGraphs />
          </Flex>
        </ResponsiveLayout>
      </Show>
    </>
  );
};
