import { getSelectedTopo } from "../reducers/topologyReducer";
import {
  DataAggregation,
  DataType,
  emsApi,
  Measure,
  MeasuredData,
  MeasuredDataDto,
  MonitoringItem,
  MonitoringResponse,
  MonitoringWithForecastWithWeatherDataByHour,
  MonitoringWithWeatherResponse,
  Unit,
} from "../services/ems.service";
import { WeatherItem } from "./WeatherItem";
import { store } from "../store/config";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  COLORS,
  DataSet,
  TimeLabels,
  YYYY_MM_DD_HH_MM_SS,
  createDataset,
  getFormatedDatetime,
  processMeasurePush,
} from "../utils/toolbox";
import "../assets/scss/components/WeatherDetails.scss";
import moment from "moment";
import EmsAlert from "./EmsAlert";

//legendes images
import { ReactComponent as Sun } from "../assets/icons/ifpen-sun.svg";
import { ReactComponent as Pylon } from "../assets/icons/ifpen-pylon.svg";
import { ReactComponent as BatteryUp } from "../assets/icons/ifpen-battery-up.svg";
import { ReactComponent as BatteryDown } from "../assets/icons/ifpen-battery-down.svg";
import { Switcher } from "./Switcher";
import { useSwitcherContext } from "../contexts/SwitcherContext";
import { Skeleton } from "@chakra-ui/react";
export interface WeatherItemType {
  temperature: number;
  unit: string;
  iconId: string;
  weatherIconUrl: string;
  description: string;
  detailsDescription: string;
}

export interface DataHour {
  hour: string;
  labels: string[];
  weather: WeatherItemType;
  datasets: DataSet[];
}

export const WeatherDetails: React.FC = () => {
  const navigate = useNavigate();
  const [maxProd, setMaxProd] = useState<number | undefined>(40);
  const [monitoringData, setMonitoringData] =
    useState<MonitoringWithWeatherResponse>();
  const [dataByHours, SetDataByHours] = useState<DataHour[] | undefined>([]);
  const topologyId = getSelectedTopo(store.getState()).id;
  const { useLazyMonitoringWithWeatherQuery } = emsApi;
  const [monitoringWithWeather, { isError: getMonitoringDataIsError }] =
    useLazyMonitoringWithWeatherQuery();
  const { switcherDate, setSwitcherDate } = useSwitcherContext();
  //refresh data every minute
  useEffect(() => {
    if (
      moment(switcherDate).format("YYYY-MM-DD") == moment().format("YYYY-MM-DD")
    ) {
      let timer: NodeJS.Timeout;
      const timout = setTimeout(() => {
        const date = moment().add(1, "hour");
        const roundedMinutes = Math.round(date.minutes() / 10) * 10;
        const startDateForecast = date.minutes(roundedMinutes).toDate();
        setSwitcherDate(startDateForecast);
        timer = setInterval(() => {
          const date = moment().add(1, "hour");
          const roundedMinutes = Math.round(date.minutes() / 10) * 10;
          const startDateForecast = date.minutes(roundedMinutes).toDate();
          setSwitcherDate(startDateForecast);
        }, 60000);
      }, (60 - new Date().getSeconds()) * 1000);
      return () => {
        window.clearInterval(timout);
        window.clearInterval(timer);
      };
    }
  }, [switcherDate]);
  useEffect(() => {
    if (topologyId == "") {
      navigate("/redirect");
    }
  }, [topologyId]);

  useEffect(() => {
    const startDate = new Date();
    startDate.setUTCHours(0, 0, 0, 0);
    monitoringWithWeather({
      date: moment(switcherDate).format(YYYY_MM_DD_HH_MM_SS),
      topologyId,
      aggregation: DataAggregation.HOUR1,
    })
      .unwrap()
      .then(
        (res) => {
          setMonitoringData(res);
        },
        (error) => {
          console.log(error);
        }
      );
  }, [topologyId, switcherDate]);

  const calculMaxData = (dataByHours: DataHour[] | undefined) => {
    if (!dataByHours) return;
    let maxByDataHour = dataByHours.map((data) => {
      let datasetsSumByLabel = data.labels.map((label, index) => {
        return data.datasets
          .map((dataset) => {
            return dataset.data.length > index ? dataset.data[index] : 0;
          })
          .map((item) => Number(item))
          .reduce((a, b) => a + b);
      });
      return Math.max(...datasetsSumByLabel);
    });
    return Math.max(...maxByDataHour);
  };

  const processMeasurePush = (
    dataOfHour: {
      [key: string]: string | MeasuredDataDto[];
    },
    key: string,
    targetArray: Measure[],
    formatter?: (item: MeasuredDataDto) => void
  ) => {
    // Filtrage et traitement des données en un seul passage
    let sortedData =
      (dataOfHour[key] as MeasuredDataDto[]).length > 1
        ? (dataOfHour[key] as MeasuredDataDto[])
        : (dataOfHour[key] as MeasuredDataDto[]).sort((item1, item2) => {
            // Comparaison des dates de manière plus sûre et efficace
            const date1 = new Date(item1.time);
            const date2 = new Date(item2.time);
            return date1.getTime() - date2.getTime();
          });

    sortedData.forEach((item) => {
      // Appel du formatteur si défini
      if (formatter) formatter(item);
      targetArray.push({
        measure: item.measure ?? 0,
        time: item.time,
        dataCategory: item.dataCategory,
      });
    });
  };

  useEffect(() => {
    const buildDataForHour = (
      dataOfHour: {
        [key: string]: string | MeasuredDataDto[];
      },
      weatherOfHour: WeatherItemType,
      hour: string
    ) => {
      const labels: TimeLabels[] = [];
      const pProdConsByConsumers: Measure[] = [];
      const pProdConsByBat: Measure[] = [];
      const pProdSentToGrid: Measure[] = [];

      processMeasurePush(
        dataOfHour,
        "pProdConsByConsumers",
        pProdConsByConsumers,
        (item) => getFormatedDatetime(item, "day", labels)
      );
      processMeasurePush(dataOfHour, "pProdConsByBat", pProdConsByBat);
      processMeasurePush(dataOfHour, "pProdSentToGrid", pProdSentToGrid);

      const graphProd: DataHour = {
        hour: hour,
        labels: labels.length > 0 ? labels.map((label) => label.label) : [],
        weather: weatherOfHour,
        datasets: [
          createDataset(
            1,
            "Autoconsommation directe",
            pProdConsByConsumers,
            Sun,
            Sun,
            COLORS.PROD,
            COLORS.PROD_OPACITY50
          ),
          createDataset(
            3,
            "Charge batterie",
            pProdConsByBat,
            BatteryUp,
            BatteryUp,
            COLORS.BATTERYUP,
            COLORS.BATTERYUP_OPACITY50
          ),
          createDataset(
            5,
            "Surplus réinjecté dans le réseau",
            pProdSentToGrid,
            Pylon,
            BatteryDown,
            COLORS.SURPLUS,
            COLORS.SURPLUS_OPACITY50
          ),
        ],
      };
      return graphProd;
    };

    SetDataByHours(() => []);
    if (!monitoringData || !monitoringData["data"]) return;
    Object.keys(monitoringData["data"])
      .sort((s1, s2) => new Date(s1).getTime() - new Date(s2).getTime())
      .forEach((hour) => {
        const data4Hour: DataHour | undefined = buildDataForHour(
          monitoringData["data"][hour].data,
          monitoringData["data"][hour].weather,
          hour
        );
        console.log("data4Hour", data4Hour);

        SetDataByHours((prev) => {
          if (prev) {
            if (data4Hour) {
              return [...prev, data4Hour];
            }
            return [...prev];
          }
          if (data4Hour) {
            return [data4Hour];
          }
          return undefined;
        });
      });
  }, [monitoringData]);
  useEffect(() => {
    let max: number | undefined = calculMaxData(dataByHours);
    if (typeof max == "number") {
      setMaxProd(() => max);
    }
  }, [dataByHours]);
  return getMonitoringDataIsError ? (
    <div className="mt-2">
      <EmsAlert
        status="error"
        title="une erreur s'est produite"
        description=""
      />
    </div>
  ) : (
    <div className="w-full h-full flex flex-col list">
      <Switcher
        withUnits={false}
        withPeriods={false}
        className="w-full px-2 mx-auto"
        customStyleInput={{
          color: "white",
          backgroundColor: COLORS.EMS_BLUE,
          paddingLeft: "14px",
          paddingRight: "14px",
        }}
        customStyleChevronLeftIcon={{
          backgroundColor: "white",
          borderRadius: "full",
        }}
        customStyleChevronRightIcon={{
          backgroundColor: "white",
        }}
      >
        <div className="w-full flex flex-col gap-2 md:mx-auto md:px-4 h-[700px] md:h-full overflow-y-auto list">
          {dataByHours &&
            dataByHours.map((data4Hour: DataHour, index: number) => {
              return (
                <Skeleton key={index} isLoaded={monitoringData != undefined}>
                  <WeatherItem
                    maxProd={maxProd ? maxProd : 40}
                    key={index}
                    data={data4Hour}
                  />
                </Skeleton>
              );
            })}
        </div>
      </Switcher>
    </div>
  );
};
