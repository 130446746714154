/* eslint-disable @typescript-eslint/no-explicit-any */
import { FlashInfoData } from "../components/NotificationsPanel";
import { createApi } from "@reduxjs/toolkit/query/react";
import { ResponseType } from "./topology.service";
import { loginAction, logoutAction } from "../reducers/authReducer";
import {
  FetchArgs,
  FetchBaseQueryError,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query";
import { BaseQueryFn } from "@reduxjs/toolkit/query";
import { RefreshTokenResponseType } from "./auth.service";
import { RootState } from "../store/config";
import { WeatherItemType } from "../components/WeatherDetails";
 
export interface SiteType extends ResponseType {
  id: number;
  name: string;
  image: string;
  value: string;
}
interface ListSiteResponse {
  siteList: SiteType[];
}
export interface MeasuredData {
  instant: number;
  measure: number;
  energy: number;
  dayTime: boolean;
  forecasted: boolean;
}

export interface MonitoringWithForecastWithWeatherDataByHour {
  [key: string]: {
    data: {
      outputActual: MonitoringResponse;
      outputForecasted: MonitoringResponse;
    };
    weather: WeatherItemType;
  };
}
export interface MonitoringWithForecastWithWeatherDataByHourResponse {
  data: MonitoringWithForecastWithWeatherDataByHour;
  totalMax: number;
}

//monitoring

// Interface pour MonitoringRequestDto
export interface MonitoringRequestDto {
  withForecast?: boolean;
  dataCategory?: DataCategory;
  topologyId: string;
  date: string;
}
export interface Measure {
  measure: number;
  time: string;
  dataCategory: DataCategory;
}
export interface MonitoringResponse {
  aggregation: string;
  [key: string]: MonitoringItem | string;
}
export interface MonitoringItem {
  [key: string]: {
    [key: string]: MeasuredDataDto[] | string;
  };
}
export interface MeasuredDataDto {
  time: string;
  type: DataType;
  measure: number;
  quality: DataQuality;
  dayTime: boolean;
  dataCategory: DataCategory;
  dataAggregation: DataAggregation;
}
export interface DataQuality {}

export enum Unit {
  A = "A", // Ampère
  V = "V", // Volt
  KW = "KW", // Kilowatt
  KWH = "kWh", // Kilowatt-heure
  EURO = "Euro", // Euro
  PCT = "Pct", // Pourcentage
  PRES = "PRES", // Hectopascal
  TEMP = "TEMP", // Température en degrés Celsius
  IRR = "IRR", // Irradiance (Watt-heure par mètre carré)
  FALL = "FALL", // Masse par mètre carré
  SPEED = "SPEED", // Vitesse en mètre par seconde
  DEG = "DEG", // Degrés
  PRICE = "PRICE", // Prix en Euro par kWh
  UNKNOWN = "UNKNOWN", // Valeur inconnue
  DPE = "DPE",
  GES = "GES",
}
// Enum pour DataType (exemple)
// Enum pour DataType avec chaque valeur égale à elle-même
export enum DataType {
  POWER = "POWER",
  ENERGY = "ENERGY",
  CONSUMPTION = "CONSUMPTION",
  CONSUMPTION_FROM_GRID = "CONSUMPTION_FROM_GRID",
  CONSUMPTION_FROM_PRODUCTION = "CONSUMPTION_FROM_PRODUCTION",
  CONSUMPTION_FROM_STORAGE = "CONSUMPTION_FROM_STORAGE",
  IRRADIANCE = "IRRADIANCE",
  PRODUCTION = "PRODUCTION",
  PRODUCTION_TO_SELL = "PRODUCTION_TO_SELL",
  PRODUCTION_SELF_CONSUMED = "PRODUCTION_SELF_CONSUMED",
  PRODUCTION_CONSUMED_BY_CONSUMERS = "PRODUCTION_CONSUMED_BY_CONSUMERS",
  PRODUCTION_CONSUMED_BY_BATTERIES = "PRODUCTION_CONSUMED_BY_BATTERIES",
  SOC = "SOC",
  STORAGE = "STORAGE",
  STORAGE_LOAD = "STORAGE_LOAD",
  STORAGE_FEED = "STORAGE_FEED",
  STORAGE_CONSUMPTION_FROM_GRID = "STORAGE_CONSUMPTION_FROM_GRID",
  STORAGE_CONSUMPTION_FROM_PROD = "STORAGE_CONSUMPTION_FROM_PROD",
  STORAGE_CONSUMPTION_FROM_BATTERIES = "STORAGE_CONSUMPTION_FROM_BATTERIES",
  STORAGE_PRODUCTION_CONSUMED_BY_CONSUMERS = "STORAGE_PRODUCTION_CONSUMED_BY_CONSUMERS",
  STORAGE_PRODUCTION_SENT_TO_GRID = "STORAGE_PRODUCTION_SENT_TO_GRID",
  STORAGE_PRODUCTION_CONSUMED_BY_BATTERIES = "STORAGE_PRODUCTION_CONSUMED_BY_BATTERIES",
  COST = "COST",
  INTENSITY = "INTENSITY",
  VOLTAGE = "VOLTAGE",
  GHI = "GHI",
  CLEAR_SKY = "CLEAR_SKY",
  TEMPERATURE = "TEMPERATURE",
  HUMIDITY = "HUMIDITY",
  WIND_DIRECTION = "WIND_DIRECTION",
  WIND_SPEED = "WIND_SPEED",
  NA = "NA",
}

// Enum pour DataAggregation (exemple)
export enum DataAggregation {
  MIN10 = "MIN10",
  HOUR1 = "HOUR1",
  DAY1 = "DAY1",
  WEEK1 = "WEEK1",
  MONTH1 = "MONTH1",
  YEAR1 = "YEAR1",
}
// Enum pour DataCategory (exemple)
export enum DataCategory {
  HISTORY = "HISTORY",
  FORECAST = "FORECAST",
}

//balance
// Interface pour HomeBalanceRequestDto
export interface HomeBalanceRequestDto {
  withForecast?: boolean;
  dataCategory?: DataCategory;
  topologyId: string;
  date: string;
}
export interface HomeBalanceResponse {
  [key: string]: HomeBalanceItem;
}
export interface HomeBalanceItem {
  [key: string]: {
    prod: HomeBalanceDataValues;
    conso: HomeBalanceDataValues;
  };
}
export interface HomeBalanceDataValues {
  battery: number;
  solar: number;
  provider: number;
}
//autonomy
// Interface pour HomeBalanceRequestDto
export interface AutonomyRequestDto {
  dataCategory?: DataCategory;
  topologyId: string;
  date: string;
}
export interface AutonomyResponse {
  [key: string]: AutonomyItem;
}
export interface AutonomyItem {
  [key: string]: {
    myAutonomy: number;
    myAutonomieWithInstallation: number;
    myAutonomieBeforeInstallation: number;
    myGain: number;
    bawData: Object | null;
  };
}
const PATH_EMS = `energyManagementSystemNew`;

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_URL}/api/${process.env.REACT_APP_API_VERSION}/`,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.user?.token;
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    headers.set("Accept", "application/json");

    return headers;
  },
});
//weather
export interface MonitoringWithWeatherResponse {
  data: {
    [key: string]: {
      data: {
        [key: string]: MeasuredDataDto[] | string;
      };
      weather: WeatherItemType;
    };
  };
}
export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // try to get a new token
    const user = (api.getState() as RootState).auth.user;
    const queryRefresh = fetchBaseQuery({
      baseUrl: `${process.env.REACT_APP_API_URL}/api/${process.env.REACT_APP_API_VERSION}/`,
    });
    const refreshResult = await queryRefresh(
      {
        url: "auth/refreshToken",
        body: { refreshToken: user?.refresh_token },
        method: "POST",
      },
      api,
      extraOptions
    );
    if (refreshResult.data) {
      // store the new token
      const data = refreshResult.data as RefreshTokenResponseType;
      api.dispatch(
        loginAction({
          isAuthenticated: true,
          user: {
            token: data.access_token,
            refresh_token: data.refresh_token,
          },
        })
      );
      // retry the initial query
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logoutAction());
      localStorage.clear();
      window.location.href = "/login";
    }
  }
  return result;
};
export const emsApi = createApi({
  reducerPath: "emsApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getEnumsList: builder.query<string[], void>({
      query: () => ({ url: `/enums/all`, method: "GET" }),
    }),
    getEnumValues: builder.query<string[], { name: string }>({
      query: ({ name }) => ({
        url: `/enums/` + name,
        method: "GET",
      }),
    }),
    monitoring: builder.query<MonitoringResponse, MonitoringRequestDto>({
      query: (params) => ({
        url: `${PATH_EMS}/monitoring`,
        method: "POST",
        body: params,
      }),
    }),
    homeBalance: builder.query<HomeBalanceResponse, HomeBalanceRequestDto>({
      query: (params) => ({
        url: `${PATH_EMS}/homebalance`,
        method: "POST",
        body: params,
      }),
    }),
    getHomeScreenData: builder.query<any, { date: Date; topologyId: string }>({
      query: (params) => ({
        url: `${PATH_EMS}/homescreen`,
        method: "POST",
        body: { ...params },
      }),
      keepUnusedDataFor: 0,
    }),
    getAutonomieIndicatorData: builder.query<
      AutonomyResponse,
      AutonomyRequestDto
    >({
      query: (params) => ({
        url: `${PATH_EMS}/autonomy`,
        method: "POST",
        body: {
          date: params.date,
          topologyId: params.topologyId,
          DataCategory: params.dataCategory,
        },
      }),
    }),
    monitoringWithWeather: builder.query<
      MonitoringWithWeatherResponse,
      {
        date?: string;
        aggregation?: string;
        topologyId: string;
      }
    >({
      query: (params) => ({
        url: `${PATH_EMS}/monitoring_manual_with_forecast_with_weather_by_hours`,
        method: "POST",
        body: {
          date: params.date,
          aggregation: params.aggregation,
          topologyId: params.topologyId,
        },
      }),
    }),
  }),
});

export const {
  useGetHomeScreenDataQuery,
  useLazyGetHomeScreenDataQuery,
  useGetAutonomieIndicatorDataQuery,
  useLazyGetAutonomieIndicatorDataQuery,
  useMonitoringQuery,
  useLazyMonitoringQuery,
  useHomeBalanceQuery,
  useLazyHomeBalanceQuery,
  useLazyMonitoringWithWeatherQuery,
  useMonitoringWithWeatherQuery,
} = emsApi;
