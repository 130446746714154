import {
  COLORS,
  getNumberFormatter,
  intervalId,
  symbolMap,
  YYYY_MM_DD_HH_MM_SS,
} from "../utils/toolbox";
import { HomePageIndicator } from "./HomePageIndicator";
import InvoicePanel from "./InvoicePanel";
import Panel from "./Panel";
import { Switcher } from "./Switcher";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { displayUnit, useSwitcherContext } from "../contexts/SwitcherContext";
import {
  DataCategory,
  HomeBalanceItem,
  HomeBalanceResponse,
  Unit,
  useLazyHomeBalanceQuery,
} from "../services/ems.service";
import { getSelectedTopo } from "../reducers/topologyReducer";
import { store } from "../store/config";
import { useNavigate } from "react-router-dom";
import { Box, Skeleton } from "@chakra-ui/react";
import moment from "moment";

export const HomePageGraphs: React.FC = () => {
  const { unit, setUnit, period, setPeriod, switcherDate, setSwitcherDate } =
    useSwitcherContext();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [data, setData] = useState<HomeBalanceResponse>();
  const topologyId = getSelectedTopo(store.getState()).id;
  const navigate = useNavigate();
  useEffect(() => {
    if (topologyId == "") {
      navigate("/redirect");
    }
  }, [topologyId]);

  useEffect(() => {
    setSwitcherDate(new Date());
    setUnit(Unit.KWH);
    setPeriod("day");
  }, []);

  const [getHomeBalanceData, { isFetching }] = useLazyHomeBalanceQuery();
  const intervalRef = useRef<number | null>(null);
// Fonction pour vérifier si switcherDate est aujourd’hui
const isToday = (date: Date) => {
  const today = new Date();
  return (
    date.getFullYear() === today.getFullYear() &&
    date.getMonth() === today.getMonth() &&
    date.getDate() === today.getDate()
  );
};
  // ⚡ Fonction qui récupère les données en fonction d'une date donnée
  const fetchHomeBalanceData = useCallback(
    (date: Date) => {
      getHomeBalanceData({
        date: moment(date).format(YYYY_MM_DD_HH_MM_SS),
        topologyId,
        dataCategory: DataCategory.HISTORY,
      })
        .unwrap()
        .then(setData)
        .catch((err) =>
          console.error("Error fetching home balance data:", err)
        );
    },
    [topologyId]
  );

  // Gère le changement manuel de `switcherDate`
  useEffect(() => {
    if (switcherDate) fetchHomeBalanceData(switcherDate);
  }, [switcherDate, topologyId]);

  // Gère les appels périodiques avec `new Date()`
  useEffect(() => {
    fetchHomeBalanceData(switcherDate); // Appel initial avec la date actuelle
      //Vérifier si switcherDate est aujourd'hui avant de démarrer l’intervalle
  if (isToday(switcherDate)) {
    intervalRef.current = window.setInterval(() => {
      fetchHomeBalanceData(new Date()); // 🕒 Toujours avec `new Date()`
    }, intervalId * 60 * 1000);
  }
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [topologyId, intervalId]);
  const formatEnergyUnit = (total: number, unit: string) => {
    if (unit == "kWh") {
      return getNumberFormatter().format(total * 1000) + "Wh";
    } else {
      return total.toFixed(1) + displayUnit(unit, symbolMap);
    }
  };
  const getGraphTotal = (type: "prod" | "conso", unit: string) => {
    if (!data || !period || !(period in data)) return "";

    const unitData = (data[period] as HomeBalanceItem)[unit.toUpperCase()]?.[
      type
    ];

    if (!unitData) return "";

    const total =
      Math.abs(unitData["battery"] || 0) +
      Math.abs(unitData["solar"] || 0) +
      Math.abs(unitData["provider"] || 0);

    return formatEnergyUnit(total, unit);
  };
  const getGraphData = (data: any, type: "prod" | "conso") => {
    if (!data || !period || !(period in data)) return [];
    const unitData = (data[period] as HomeBalanceItem)[unit.toUpperCase()][
      type
    ];
    return [
      parseFloat(getNumberFormatter().format(unitData["solar"])),
      parseFloat(getNumberFormatter().format(unitData["provider"])),
      parseFloat(getNumberFormatter().format(unitData["battery"])),
    ];
  };
  const getEnergyDistribution = (data: any, type: "prod" | "conso") => {
    if (!data || !period || !(period in data)) return [];
    const unitData = (data[period] as HomeBalanceItem)[unit.toUpperCase()]?.[
      type
    ];
    if (type == "prod") {
      return [
        {
          label: "Production solaire directement consommée",
          color: COLORS.PROD,
          value: formatEnergyUnit(unitData["solar"], unit),
        },
        {
          label: "Surplus réinjecté dans le réseau",
          color: COLORS.SURPLUS,
          value: formatEnergyUnit(unitData["provider"], unit),
        },
        {
          label: "Charge batterie",
          color: COLORS.BATTERYUP,
          value: formatEnergyUnit(unitData["battery"], unit),
        },
      ];
    } else {
      return [
        {
          label: "Production solaire directement consommée",
          color: COLORS.PROD,
          value: formatEnergyUnit(Math.abs(unitData["solar"]), unit),
        },
        {
          label: "Consommation issue du réseau",
          color: COLORS.CONSO,
          value: formatEnergyUnit(Math.abs(unitData["provider"]), unit),
        },
        {
          label: "Décharge batterie",
          color: COLORS.BATTERYDOWN,
          value: formatEnergyUnit(Math.abs(unitData["battery"]), unit),
        },
      ];
    }
  };
  return (
    <Box className="w-full flex flex-col items-center gap-4">
      <Skeleton isLoaded={!isFetching} className="w-full md:max-w-fit">
        <Panel className="w-full md:max-w-fit">
          <Switcher withUnits={false}>
            <InvoicePanel
              homeBalanceData={
                data &&
                (data[period] as HomeBalanceItem)["Euro".toUpperCase()]["prod"]
              }
            />
          </Switcher>
        </Panel>
      </Skeleton>
      <Skeleton isLoaded={!isFetching} className="w-full md:max-w-fit">
        <HomePageIndicator
          globalValue={unit === Unit.PCT ? "" : getGraphTotal("prod", unit)}
          title="Ma production globale d'énergie"
          link="/indicators"
          graphData={{
            backgroundColor: [COLORS.PROD, COLORS.SURPLUS, COLORS.BATTERYUP],
            labels: [
              "Directement consommée",
              "Surplus réinjecté",
              "Charge Batterie",
            ],
            datas: getGraphData(data, "prod"),
          }}
          unit={unit}
          energyDistribution={getEnergyDistribution(data, "prod")}
        />
      </Skeleton>
      <Skeleton isLoaded={!isFetching} className="w-full md:max-w-fit">
        <HomePageIndicator
          globalValue={unit === Unit.PCT ? "" : getGraphTotal("conso", unit)}
          title="Ma consommation globale d'énergie"
          link="/indicators"
          graphData={{
            backgroundColor: [COLORS.PROD, COLORS.CONSO, COLORS.BATTERYDOWN],
            labels: ["Production solaire", "Réseau", "Décharge batterie"],
            datas: getGraphData(data, "conso"),
          }}
          unit={unit}
          energyDistribution={getEnergyDistribution(data, "conso")}
        />
      </Skeleton>
    </Box>
  );
};
